import React, { useEffect, useState } from "react";
import Hero from "../Hero";
import AboutSection from "../Section/AboutSection";
import BrandsSection from "../Section/BrandsSection";
import Banner from "../Section/BannerSection";
import Section from "../Section";
import FeaturesSection from "../Section/FeaturesSection";
import TestimonialSection from "../Section/TestimonialSection";
import BlogSection from "../Section/BlogSection";
import AppointmentSection from "../Section/AppointmentSection";
import FaqSection from "../Section/FaqSection";
import TeamSectionStyle4 from "../Section/TeamSection/TeamSectionStyle4";
import { pageTitle } from "../../helpers/PageTitle";
import ContactInfoSection from "../Section/ContactInfoSection";
import { getFiles } from "../../services/assets";
const featureListData = [
  {
    iconSrc: "/images/home_1/compassion.svg",
    title: "Honestidad",
    subTitle:
      "Creemos en practicar la medicina con integridad y honestidad. Somos transparentes en nuestros procesos de comunicación y toma de decisiones, y siempre anteponemos los intereses de nuestros pacientes y brindamos la mejor solución.",
  },
  {
    iconSrc: "/images/home_1/excellence.svg",
    title: "Calidad",
    subTitle:
      "Estamos comprometidos a brindar excelente atención y servicios médicos a nuestros pacientes. Creemos en mejorar continuamente nuestras habilidades, conocimientos y recursos para garantizar que brindamos la atención de la más alta calidad posible.",
  },
  {
    iconSrc: "/images/home_1/integrity.svg",
    title: "Responsabilidad",
    subTitle: `Reconocemos que la responsabilidad en la atención médica es crucial para garantizar la calidad y seguridad de los tratamientos que ofrecemos. Nos dedicamos a cumplir con los más altos estándares profesionales, asumiendo el compromiso de cuidar de cada paciente con diligencia y esmero, asegurándonos de que reciban la mejor atención posible en todo momento.`,
  },
  {
    iconSrc: "/images/home_1/respect.svg",
    title: "Respeto",
    subTitle:
      "Tratamos a todas las personas con respeto y dignidad, independientemente de sus antecedentes, creencias o circunstancias. Creemos que cada persona merece ser tratada con compasión y amabilidad.",
  },
  {
    iconSrc: "/images/home_1/teamwork.svg",
    title: "Trabajo en equipo",
    subTitle:
      "Creemos en trabajar en colaboración con los miembros de nuestro equipo y otros profesionales de la salud para brindar una atención integral y eficaz a nuestros pacientes.",
  },
];
const brandData = ["images/blog/1-min.jpg", "images/blog/2-min.jpg"];
const faqData = [
  {
    title: "What services does ProHealth offer?",
    content:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.",
  },
  {
    title: "How do I schedule an appointment with ProHealth?",
    content:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.",
  },
  {
    title: "Do you accept insurance?",
    content:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.",
  },
  {
    title: "What should I bring to my appointment?",
    content:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.",
  },
  {
    title: "How do I request a prescription refill?",
    content:
      "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesent voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui.",
  },
];
const blogData = [
  {
    title: "The Benefits of Mindfulness Meditation for Stress and Anxiety",
    thumbUrl: "images/home_1/post_1.jpeg",
    date: "May 1, 2023",
    btnText: "Learn More",
    href: "/blog/blog-details",
    socialShare: true,
  },
  {
    title: "Healthy Eating on a Budget: Tips and Strategies",
    thumbUrl: "images/home_1/post_2.jpeg",
    date: "May 4, 2023",
    btnText: "Learn More",
    href: "/blog/blog-details",
    socialShare: true,
  },
  {
    title: "The Importance of Regular Cancer Screenings and Early Detection",
    thumbUrl: "images/home_1/post_3.jpeg",
    date: "May 1, 2023",
    btnText: "Learn More",
    href: "/blog/blog-details",
    socialShare: true,
  },
];
const teamData = [
  {
    imgUrl: "/images/home_3/SEIJI.jpg",
    name: "Dr. Seiji Broca Coronel",
    designation: "Médico Especialista en Medicina Interna",
    description:
      "Con experiencia de más de una decada en diversos padecimeinto en relacion a la medicina interna",
  },
  {
    imgUrl: "/images/home_3/monica.jpg",
    name: "Q.F.B. Mónica González Ortiz",
    designation: "Química Farmacobióloga",
    description:
      "Experiencia de más de 30 años en el diagnostico por campo clinico experta en cultivos",
  },
  {
    imgUrl: "/images/home_3/laura.jpg",
    name: "Dra. Laura Patricia Sanchez Telles",
    designation: "Médico General",
    description:
      "Experiencia en atención de primer nivel de mas de 10 años en medicina general",
  },
  ,
  {
    imgUrl: "/images/home_3/victoria.jpg",
    name: "Dra. Victoria Del Carmen Campos Vidal",
    designation: "Médico General",
    description:
      "Experiencia en atención en medicina general y área hospitalaria.",
  },
  ,
  {
    imgUrl: "/images/home_3/betsy.jpg",
    name: "Lic. Betsy Paula Jesús Jimenez",
    designation: "Nutrióloga Especialista en Diabetes Mellitus",
    description:
      "Experta en seguimiento nutricional y en enfermedades metabólicas. ",
  },
];

export default function Home() {
  pageTitle("Inicio");
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const init = async () => {
      setFiles(await getFiles());
    };

    init();
  }, []);

  return (
    <>
      <Hero
        title="Tu Aliado en Salud y Bienestar"
        subTitle="Estamos comprometidos en brindarle los mejores servicios médicos y de atención médica para a vivir más sano y feliz."
        bgUrl="/images/home_1/hero_bg.jpeg"
        imgUrl="/images/home_1/banner.png"
        videoBtnText="Agendar una cita"
        videoUrl="https://www.youtube.com/embed/VcaAVWtP48A"
        infoList={[
          {
            title: "Llamadas",
            subTitle: "993-201-9578",
            iconUrl: "/images/contact/icon_1.svg",
          },

          {
            title: "Sucursal",
            subTitle: "Villahermosa, TAB",
            iconUrl: "/images/icons/pin.svg",
          },
        ]}
        btnText="Agendar ahora"
        btnUrl="https://api.whatsapp.com/send?phone=5219932019578&text=Hola%2C%20me%20gustar%C3%ADa%20agendar%20una%20citahttps://api.whatsapp.com/send?phone=5219932019578&text=Hola%2C%20me%20gustar%C3%ADa%20agendar%20una%20cita"
      />

      {/* Start About Section */}
      <div id="nosotros" />
      <Section topMd={185} topLg={140} topXl={100}>
        <AboutSection
          imgUrl="/images/home_1/medical.jpg"
          spiningImgUrl="/images/home_1/about_mini.svg"
          title="Acerca de nosotros"
          subTitle="Centro Médico de Salud Laboral"
          featureList={[
            {
              featureListTitle:
                "CEMEDSL es un equipo de profesionales médicos experimentados",
              featureListSubTitle:
                "Dedicados a brindar servicios de salud de la más alta calidad. Creemos en un enfoque personalizado de la atención médica que se centra en tratar a cada individuo según sus necesidades específicas, no solo la enfermedad o los síntomas.",
            },
          ]}
        />
      </Section>
      {/* End About Section */}
      {/* Start Feature Section */}

      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={185}
        bottomLg={140}
        bottomXl={100}
      >
        <FeaturesSection
          sectionTitle="Nuestros Valores"
          data={featureListData}
        />
      </Section>
      {/* End Feature Section */}

      {/* Start Departments Section */}
      {/*  <Section topMd={185} topLg={150} topXl={110}>
        <DepartmentSection
          sectionTitle="Departments"
          bgUrl="images/home_1/department_bg.svg"
          data={departmentData}
        />
      </Section> */}
      <div id="medicos" />
      <Section topMd={195} topLg={145} topXl={105}>
        <TeamSectionStyle4
          sectionTitle="Médicos Expertos"
          sectionTitleUp="Conoce nuestros"
          data={teamData}
        />
      </Section>
      {/* End Departments Section */}
      {/* Start Award Section */}
      {/*
        <AwardSection sectionTitle="Departments" data={awardData} />
      </Section>
      <Section topMd={185} topLg={140} topXl={100}>
      */}
      {/* End Award Section */}
      {/* Start Testimonial */}
      <div id="testimonios" />
      <Section
        topMd={185}
        topLg={140}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <TestimonialSection
          sectionTitle="Algunos Testimonios"
          sectionTitleDown="DE NUESTROS PACIENTES"
        />
      </Section>
      {/* End Testimonial */}
      {/* Start Banner Section */}
      <div id="promociones" />
      <Section>
        <Banner
          bgUrl="images/home_1/cta_bg.svg"
          images={files}
          title="¡No dejes que tu salud pase a un segundo plano!"
          subTitle="¡Programe una cita hoy con uno de nuestros profesionales médicos!"
        />
      </Section>
      {/* End Banner Section */}
      {/* Start Blog Section */}
      {/*  <Section topMd={190} topLg={145} topXl={105}>
        <BlogSection
          sectionTitle="Latest Update"
          sectionTitleUp="BLOG POSTS"
          data={blogData}
        />
      </Section> */}
      {/* End Blog Section */}
      {/* Start Appointment Section */}
      {/*  <Section topMd={190} topLg={145} topXl={105} id="appointment">
        <AppointmentSection
          sectionTitle="Appointment"
          sectionTitleUp="BOOK AN"
          imgUrl="/images/home_1/appointment.jpeg"
        />
      </Section> */}
      {/* End Appointment Section */}
      {/* Start FAQ Section */}
      <div id="contacto" />
      <Section
        topMd={200}
        topLg={150}
        topXl={100}
        bottomMd={200}
        bottomLg={150}
        bottomXl={110}
      >
        <ContactInfoSection sectionTitle="Encuentranos aquí" />
      </Section>
      {/* End FAQ Section */}
      {/* Start Brand Section */}
      <Section topMd={0} bottomMd={50}>
        <BrandsSection data={brandData} />
      </Section>
      {/* End Brand Section */}
    </>
  );
}
