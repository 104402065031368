import "../config/firebase";
import { addDoc, collection, getFirestore } from "firebase/firestore";

const db = getFirestore();

export const registerMail = async (email) => {
  const docRef = collection(db, "newsletter");

  const createdAt = new Date();
  const updatedAt = new Date();

  return await addDoc(docRef, {
    email,
    createdAt,
    updatedAt,
  });
};
