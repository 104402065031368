import React, { useState } from "react";
import Rating from "../Rating";

export default function Testimonial() {
  const [activeTab, setActiveTab] = useState(2);
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  return (
    <div className="cs_tabs cs_style1">
      <ul className="cs_tab_links">
        <li className={activeTab === 1 ? "active" : ""}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(1)}>
            <div className="cs_testimonial_1_avatar">
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">RODRIGO CARRERA</h3>
                <p className="cs_heading_color mb-0">Tuxtla Gutierrez, CHIS</p>
              </div>
            </div>
          </div>
        </li>
        <li className={activeTab === 2 ? "active" : ""}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(2)}>
            <div className="cs_testimonial_1_avatar">
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">EUGENIA SALAZAR</h3>
                <p className="cs_heading_color mb-0">Mérida, YUC</p>
              </div>
            </div>
          </div>
        </li>
        <li className={activeTab === 3 ? "active" : ""}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(3)}>
            <div className="cs_testimonial_1_avatar">
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">CRISTINA VASQUÉZ</h3>
                <p className="cs_heading_color mb-0">Villahermosa, TAB</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div className="cs_tab_body">
        {activeTab === 1 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" />
            <p>
              Recientemente tuve que llevar a mi hijo a CEMEDSL por una lesión
              menor y quedé muy impresionado con la atención que recibió. El
              médico fue genial con él y lo hizo sentir a gusto, y todo el
              personal fue amable y atento.
            </p>
            <Rating ratingNumber={5} />
          </div>
        )}
        {activeTab === 2 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" />
            <p>
              Mi experiencia en CEMEDSL con el médico internista fue
              excepcional. Desde el momento en que llegué, el personal fue muy
              amable y atento. El doctor mostró un alto nivel de profesionalismo
              y dedicación, escuchando atentamente mis preocupaciones y
              realizando un examen exhaustivo. Me explicó cada aspecto de mi
              diagnóstico y tratamiento con claridad, lo que me dio mucha
              confianza. Gracias a su enfoque meticuloso y cuidadoso, me siento
              mucho mejor y muy agradecido por la atención recibida. Sin duda,
              recomendaría CEMEDSL a cualquiera que busque atención médica de
              calidad.
            </p>
            <Rating ratingNumber={4.5} />
          </div>
        )}
        {activeTab === 3 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" />
            <p>
              Estoy muy agradecido con el CEMEDSL por la atención excepcional
              que recibí de la nutrióloga especialista en diabetes. Desde el
              primer día, me sentí escuchado y comprendido. La nutrióloga me
              proporcionó un plan de alimentación personalizado que no solo se
              ajusta a mis necesidades médicas, sino que también se adapta a mi
              estilo de vida. Gracias a su orientación experta, he logrado
              controlar mis niveles de azúcar en sangre de manera efectiva y
              mejorar mi bienestar general. Su profesionalismo y dedicación son
              realmente admirables. Recomiendo ampliamente sus servicios a
              cualquier persona que busque una atención integral y de calidad.
            </p>
            <Rating ratingNumber={4.5} />
          </div>
        )}
      </div>
    </div>
  );
}
