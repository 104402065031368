import React, { useCallback, useState } from "react";
import Spacing from "../../Spacing";
import ImageViewer from "react-simple-image-viewer";

export default function BrandsSection({ data, sectionTitle }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <div className="container">
      {sectionTitle && (
        <>
          <h2 className="cs_fs_40 text-center mb-0 cs_semibold">
            {sectionTitle}
          </h2>
          <Spacing md="60" />
        </>
      )}

      <div className="cs_brands cs_style_1">
        {data?.map((item, index) => (
          <div className="cs_brand cs_center" key={index}>
            <img
              src={item}
              alt="Instalacion"
              onClick={() => openImageViewer(index)}
              key={index}
            />
          </div>
        ))}
        {isViewerOpen && (
          <ImageViewer
            src={data}
            currentIndex={currentImage}
            onClose={closeImageViewer}
            disableScroll={false}
            backgroundStyle={{
              backgroundColor: "rgba(0,0,0,0.9)",
            }}
            closeOnClickOutside={true}
          />
        )}
      </div>
    </div>
  );
}
