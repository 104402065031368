import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";

import React from "react";

export default function Banner({ bgUrl, images, title, subTitle }) {
  return (
    <div className="container">
      <div
        className="cs_banner cs_style_1 cs_bg_filed cs_custom_banner"
        style={{
          backgroundImage: `url(${bgUrl})`,
        }}
      >
        <div>
          <h2 className="cs_banner_title cs_white_color cs_fs_72">{title}</h2>
          <p className="cs_banner_subtitle cs_heading_color cs_fs_20 cs_medium m-0">
            {subTitle}
          </p>
          <div className="cs_hero_info_col cs_fs_20" style={{ marginTop: 12 }}>
            <a
              className="cs_btn cs_style_1"
              href="https://api.whatsapp.com/send?phone=5219932019578&text=Hola%2C%20me%20gustar%C3%ADa%20agendar%20una%20citahttps://api.whatsapp.com/send?phone=5219932019578&text=Hola%2C%20me%20gustar%C3%ADa%20agendar%20una%20cita"
              target="_blank"
              rel="noreferrer"
            >
              <span>Agendar ahora</span>
              <i>
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
                <img src="/images/icons/arrow_white.svg" alt="Icon" />
              </i>
            </a>
          </div>
        </div>
        <Splide
          aria-label="Promociones"
          className="promociones"
          options={{
            type: "loop",
            perPage: 1,
            autoplay: true,
            height: 375,
          }}
        >
          {images.map((imgUrl) => (
            <SplideSlide>
              <img
                src={imgUrl}
                alt="Banner"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
}
