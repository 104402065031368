import React from "react";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import MenuWidget from "../Widget/MenuWidget";
import SocialWidget from "../Widget/SocialWidget";
import Newsletter from "../Widget/Newsletter";
import TextWidget from "../Widget/TextWidget";
const menuDataOne = [
  { title: "Nostros", href: "#nosotros" },
  { title: "Médicos", href: "#medicos" },
  { title: "Testimonios", href: "#testimonios" },
  { title: "Promociones", href: "#promociones" },
  { title: "Contacto", href: "#contacto" },
];
const menuDataTwo = [
  { title: "Blog", href: "/blog" },
  { title: "Contact Us", href: "/contact" },
  { title: "FAQs", href: "/" },
  { title: "Privacy Policy", href: "/" },
  { title: "Terms and Conditions", href: "/" },
];

export default function Footer() {
  return (
    <footer className="cs_footer cs_style_1 cs_heading_color">
      <div
        className="cs_footer_logo_wrap"
        style={{ backgroundImage: "url(/images/footer_bg_1.svg)" }}
      >
        <div
          className="cs_footer_brand"
          style={{ backgroundImage: "url(/images/cemedsl.svg)" }}
        />
      </div>
      <div className="cs_footer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <TextWidget text="Centro Médico de <br />Salud Laboral" />
                <ContactInfoWidget />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <MenuWidget data={menuDataOne} />
              </div>
            </div>
            {/*   <div className="col-lg-2">
              <div className="cs_footer_item">
                <MenuWidget data={menuDataTwo} />
              </div>
            </div> */}
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <Newsletter
                  title="Suscríbete"
                  subTitle="Para recibir las últimas noticias sobre salud de nuestros expertos"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cs_footer_bottom cs_accent_bg">
        <div className="container">
          <div className="cs_footer_bottom_in">
            <SocialWidget />
            <div className="cs_copyright">
              Copyright © {new Date().getFullYear()} Centro Médico de Salud
              Laboral. Todos los Derechos Reservados. |
              <a>
                {" "}
                Hecho con ❤️ por <a href="https://devimo.io">DEVIMO</a>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
