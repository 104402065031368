import "../config/firebase";
import { getDownloadURL, getStorage, listAll, ref } from "firebase/storage";

const storage = getStorage();

export const getFiles = async () => {
  const listRef = ref(storage, "/");
  const res = await listAll(listRef);
  const fileUrls = await Promise.all(
    res.items.map((itemRef) => getDownloadURL(itemRef))
  );

  return fileUrls;
};
