import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";

export default function SocialWidget() {
  return (
    <div className="cs_social_links_wrap">
      <h2>Síguenos</h2>
      <div className="cs_social_links">
        <a
          href="https://www.facebook.com/CemedSL"
          target="_blank"
          rel="noreferrer"
        >
          <Icon icon="fa-brands:facebook-f" />
        </a>

        <a
          href="https://mx.linkedin.com/in/cemed-sl-867820288"
          target="_blank"
          rel="noreferrer"
        >
          <Icon icon="fa-brands:linkedin-in" />
        </a>
      </div>
    </div>
  );
}
