import React, { useState } from "react";
import { registerMail } from "../../services/users";

export default function NewsletterForm({ label, btnText, btnArrowUrl }) {
  const [email, setEmail] = useState("");

  return (
    <>
      {label && <p>Your Email</p>}
      <form action="#" className="cs_newsletter_form">
        <input
          type="text"
          value={email}
          className="cs_form_field"
          placeholder="example@email.com"
          style={{ paddingRight: "195px" }}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button
          className="cs_btn cs_style_1"
          onClick={async (e) => {
            e.preventDefault();
            await registerMail(email);
            setEmail("");
          }}
        >
          <span>{btnText}</span>
          <i>
            <img src={btnArrowUrl} alt="Icon" />
            <img src={btnArrowUrl} alt="Icon" />
          </i>
        </button>
      </form>
    </>
  );
}
