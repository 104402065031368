import React from "react";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import Button from "../Button";
import { pageTitle } from "../../helpers/PageTitle";

export default function ErrorPage() {
  pageTitle("Error");
  return (
    <div className="cs_error cs_center text-center cs_gray_bg_1">
      <div className="container">
        <SectionHeading
          title="¡Vaya, parece que esta página está de vacaciones! 🏝️"
          titleUp="Error 404"
          subTitle="No te preocupes, tu salud no se ha perdido. Mientras encontramos el camino de regreso, ¿qué tal si visitas nuestra página principal o contactas con nosotros directamente? ¡Prometemos cuidarte mejor que a nuestra página 404! 😄"
          variantColor="cs_white_color"
        />
        <Spacing lg="30" md="30" />
        <Button btnText="Volver al Inicio" btnUrl="/" />
      </div>
    </div>
  );
}
